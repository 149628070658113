<template>
  <div class="page-gray">
    <div style="height: 3px"></div>
    <div class="studentItem" v-for="item in studentList " :key="item.id" @click="switchStudent(item)">
      <div class="avater">
        <img :src="item.photo | Avatar"  alt="">
        <div class="thisAvater" v-if="item.id == currentStudentId">当前</div>
      </div>
      <div class="student">
        <div class="name">
          {{ nameDesensitization(item.receiverName) }}
          <span v-if="item.phone"> {{ phoneDesensitization(item.phone) }}</span>
        </div>

        <div class="bottom">
          <div class="item">
            <div class="value"> {{ item.school }}</div>
          </div>
        </div>
        <div class="bottom-grade">
          {{ item.grade }}   {{ item.classn }}
        </div>
      </div>
      <div class="rightInfo">
        <div class="logout" @click.stop="deleteStudent(item)">注销</div>
        <div class="editStudent"  @click.stop="$router.push({path:`/student/edit/${item.id}`,query:{schoolId:item.schoolid}})">编辑</div>
      </div>
    </div>


    <div class="addItem" @click="$router.push('/enterSchoolCode')">
      <img src="./../../assets/images/student/add.png" alt="">
      <p>添加学生</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {desensitizeName, maskPhoneNumber} from "../../util/util";
import {mapState} from 'vuex'
export default {
  name: "newStudentList",
  data() {
    return {
      currentStudentId: '1',
      studentList: []
    }
  },
  computed:{
    ...mapState(['studentInfo',]),
  },
  activated() {
    this.init();
    this.currentStudentId = this.studentInfo.id;
  },
  methods: {
    init(){
      this.$http.get(`/mall/h5_mall/queryAllStudentByUserId`, {
        params: {
          userId: Vue.prototype.userId
        }
      }, {
        emulateJSON: true
      }).then(res => {
        this.studentList = res.data;
      })
    },
    switchStudent(item) {
      this.$dialog.confirm({
        message: `确定以${item.receiverName}进入商城`,
      }).then(() => {
        //添加默认学生
        this.$http.post(this.baseMallUrl + `/h5_mall/switchDefaultStudents/${item.id}`, {}, {emulateJSON: true}).then(() => {
          //操作成功 获取学校信息
          this.$router.push({path: '/', query: {}})
        })
      }).catch(() => {
      });
    },
    deleteStudent(item){
      this.$dialog.confirm({
        title: `确定要删除${item.receiverName}学生？`,
        message: `删除后该学生注册信息将被删除，且不可恢复，历史订单不受影响`,
      }).then(() => {
        //添加默认学生
        this.$http.post(this.baseMallUrl + `/h5_mall/delStudent/${item.id}`, {}, {emulateJSON: true}).then(() => {
          //操作成功 获取学校信息
          this.init();
        })
      }).catch(() => {
      });
    },
    nameDesensitization(name){
      return desensitizeName(name);
    },
    phoneDesensitization(phone){
      if(phone){
        return maskPhoneNumber(phone)
      }else{
        return ''
      }

    }
  },
}

</script>
<style lang="less" scoped>
.studentItem {
  padding: 20px 20px 15px 20px;
  background: #FFFFFF;
  box-shadow: 0px 5px 12px 0px rgba(217, 226, 233, 0.5);
  border-radius: 8px;
  margin: 10px;
  position: relative;

  .edit {
    position: absolute;
    top: 14px;
    right: 19px;
    height: 12px;
    width: 12px;
  }

  .del {
    position: absolute;
    top: 1px;
    left: 5px;
    width: 50px;
    font-size: 12px;
    color: #828282;
  }

  .avater {
    min-width: 57px;
    width: 57px;
    height: 57px;
    position: relative;
    border-radius: 50%;
    display: inline-block;
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }

    .current {
      height: 12px;
      width: 12px;
      position: absolute;
      bottom: 0;
      right: 6px;
    }

    .thisAvater{
      background: #FF553E;
      font-size: 10px;
      color: #fcfafa;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      padding-left: 5px;
      width: 60%;
      position: absolute;
      left: 12px;
      top: 45px;
    }
  }

  .student {
    display: inline-block;
    margin-left: 13px;

    .name {
      font-size: 15px;
      color: #212121;
      margin: 7px 0 2px 0;
      line-height: 1;

      span {
        font-size: 12px;
        color: #D3640A;
        background: linear-gradient(to right, #F9CD4E, #FFDA85);
        border-radius: 4px;
        padding: 0 10px;
      }
    }
    .bottom-grade{
      color: #999999;
      font-size: 12px;
    }
    .address {
      line-height: 12px;
      margin-bottom: 20px;

      span {
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #CCCCCC;
        border-radius: 50%;
        margin-right: 6px;
        vertical-align: middle;
      }

      font-size: 12px;
      color: #212121;
    }

    .bottom {
      .item {
        display: inline-block;
        margin-right: 35px;

        .key {
          line-height: 11px;
          font-size: 11px;
          color: #C6C6C6;
        }

        .value {
          line-height: 12px;
          font-size: 12px;
          color: #FF553E;
          background: #FFEDE4;
        }
      }
    }
  }

  .rightInfo{
    display: flex; /* 使用flex布局 */
    justify-content: flex-end; /* 项目向右对齐 */
    font-size: 13px;
    text-align: center;

    .logout{
      background: #D9E2E9;
      display: inline-block;
      width: 70px;
      color: #333333;
      padding: 5px 10px;
      border-radius: 40px;
    }

    .editStudent{
      background: #FF553E;
      display: inline-block;
      padding: 5px 10px;
      width: 70px;
      color: #FFFFFF;
      border-radius: 40px;
      margin-left: 10px;
    }
  }
}

.addItem {
  height: 90px;
  background: #FFFFFF;
  box-shadow: 0px 5px 12px 0px rgba(217, 226, 233, 0.5);
  border-radius: 8px;
  margin: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 35px;
    width: 35px;
    margin-bottom: 17px;
  }

  p {
    font-size: 12px;
    color: #212121;
    line-height: 1;
    text-align: center;
  }
}
</style>
